import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "@sweetalert2/themes/default/default.css";
// import '@sweetalert2'

// we'll add the React wrapper around the vanilla JS lib,
// just in case if we need to pass custom React nodes like icons or elements
const ReactSwal = withReactContent(Swal);

const SweetAlertToast = ReactSwal.mixin({
  toast: true,
  position: "top-right",
  iconColor: "white",
  customClass: {
    popup: "colored-toast",
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
});

type ToastOptions = {
  type?: "Success" | "Error" | "Info" | "Warning" | "Question";
  title?: string;
  timeout?: number;
  showCancelButton?: boolean;
  confirmButtonText?: string;
  showConfirmButton?: boolean;
};

const FIVE_SECONDS = 5000;
const TEN_SECONDS = 10000;

export function Toast(options: ToastOptions): Promise<any> {
  const type = options.type ?? "Success";
  const title = options.title ?? "Success";
  // make the default timeout for errors longer
  const timeout =
    options.timeout ?? type === "Error" ? TEN_SECONDS : FIVE_SECONDS;

  const icon = typeIconMap[type];

  // TS compiler is complaining about this
  // @ts-ignore
  return SweetAlertToast.fire({
    icon,
    title,
    timer: timeout,
    showCancelButton: options.showCancelButton ?? false,
    showConfirmButton: options.showConfirmButton ?? false,
    confirmButtonText: options.confirmButtonText ?? "Confirm",
  });
}

const typeIconMap = {
  Success: "success",
  Error: "error",
  Info: "info",
  Warning: "warning",
  Question: "question",
};
